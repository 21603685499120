<template>
  <div>
    <div class="d-flex justify-content-between mb-10">
      <div class="form-group mb-0 col-xl-6 px-0">
        <input
          id="project_report_table_search"
          v-model="search"
          type="text"
          :placeholder="$t('GENERAL.INPUT.SEARCH')"
          class="form-control form-control-solid h-auto py-3 px-5 w-75"
          :disabled="loadingComplete == false"
        />
      </div>
      <b-dropdown
        id="project-report-status-select"
        class="table-filter"
        size="sm"
        :text="status_select.text"
        :variant="status_select.variant"
      >
        <b-dropdown-item @click="setStatusSelect(1)">
          {{ $t('SYSTEM.REPORT_STATUS.1') }}
        </b-dropdown-item>
        <b-dropdown-item @click="setStatusSelect(2)">
          {{ $t('SYSTEM.REPORT_STATUS.2') }}
        </b-dropdown-item>
        <b-dropdown-item @click="setStatusSelect(3)">
          {{ $t('SYSTEM.REPORT_STATUS.3') }}
        </b-dropdown-item>
        <b-dropdown-item @click="setStatusSelect(4)">
          {{ $t('SYSTEM.REPORT_STATUS.4') }}
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="setStatusSelect(null)">
          {{ $t('SYSTEM.REPORT_STATUS.ANY') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="table-responsive">
      <table
        id="project_reports_table"
        class="table table-head-custom table-vertical center table-hover"
        style="table-layout:auto; overflow: auto"
      >
        <thead>
          <tr>
            <th class="px-2 py-3" rowspan="2" style="min-width:60px">
              #
            </th>
            <th class="px-2 py-3" rowspan="2" style="min-width:300px">
              {{ $t('TABLES.PROJECT_REPORTS.TITLE') }}
            </th>
            <th class="px-2 py-3 text-center" rowspan="2" style="min-width:150px">
              {{ $t('TABLES.PROJECT_REPORTS.STATUS') }}
            </th>
            <th class="px-2 py-3 text-center" rowspan="2" style="min-width:100px">
              {{ $t('TABLES.PROJECT_REPORTS.PII') }}
            </th>
            <th class="border-bottom-0 py-2 px-4 text-left" style="min-width:100px">
              {{ $t('TABLES.PROJECT_REPORTS.COMPLETED_ON') }}
            </th>
            <th class="border-bottom-0 py-2 px-4 text-left" style="min-width:100px">
              {{ $t('TABLES.PROJECT_REPORTS.CREATED_ON') }}
            </th>
            <th class="text-right pr-7" style="min-width:200px">
              {{ $t('TABLES.PROJECT_HEADERS.ACTIONS') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(projectReport, i) in projectReports">
            <tr
              v-bind:key="i"
              v-if="
                reportInSearch(projectReport) &&
                  projectReports.length > 0 &&
                  loadingComplete && reportInStatusFilter(projectReport)
              "
              v-on:mouseover="hoverRow = i"
              v-on:mouseleave="hoverRow = null" 
            >
              <td>
                <span
                  class="text-muted font-weight-bold text-muted d-block font-size-sm text-left pt-2"
                >
                  {{ projectReport.id }}
                </span>
              </td>
              <td class="px-2 py-3 d-table-cell">
                <a
                  href="javascript:void(0)"
                  class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
                >
                  {{ projectReport.title }}
                </a>
                <span
                  class="text-muted font-weight-bold text-muted d-block font-size-sm"
                >
                  {{ $t('GENERAL.TEXT.CREATED_BY') }}:  {{ projectReport.user.display_name }}
                </span>
              </td>
              <td class="px-2 py-4 text-center d-table-cell">
                <span :class="'label label-md font-weight-bold label-rounded w-auto px-2 mt-1 label-' + statusFormat(projectReport.status)">
                  {{ $t('SYSTEM.REPORT_STATUS.' + projectReport.status) }}
                </span>
              </td>
              <td class="px-2 py-4 text-center d-table-cell">
                <span
                  class="text-muted font-weight-bold text-muted d-block font-size-sm text-left pt-2 text-center"
                >
                  {{ (projectReport.contains_pii) ? $t("GENERAL.TEXT.YES") : $t("GENERAL.TEXT.NO") }}
                </span>
              </td>
              <td class="text-left py-5 font-size-sm text-muted">
                <GenericDatetimeDisplay
                  v-if="projectReport.completed_at != null"
                  :long="true"
                  :date="projectReport.completed_at"
                  :lastUpdated="false"
                  :relative="false"
                >
                </GenericDatetimeDisplay>
              </td>
              <td class="text-left py-5 font-size-sm text-muted">
                <GenericDatetimeDisplay
                  :long="true"
                  :date="projectReport.created_at"
                  :createdAt="true"
                  :lastUpdated="false"
                  :relative="false"
                >
                </GenericDatetimeDisplay>
              </td>
              <td class="text-right py-0 font-size-sm">
                <div v-if="hoverRow == i" class="pt-4 px-3">
                  <a
                    v-if="projectReport.status == 3"
                    href="javascript:void(0)"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
                    v-b-tooltip.hover.bottom
                    :title="$t('TABLES.PROJECT_REPORT_TOOLTIPS.DOWNLOAD')"
                    @click="downloadReport(projectReport)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Files/Download.svg"
                      />
                    </span>
                  </a>
                  <!-- <a
                    v-if="projectReport.status == 3"
                    href="javascript:void(0)"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
                    v-b-tooltip.hover.bottom
                    :title="$t('TABLES.PROJECT_REPORT_TOOLTIPS.REPEAT')"
                    @click="repeatReport(projectReport)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Media/Repeat.svg"
                      />
                    </span>
                  </a> -->
                  <a
                    href="javascript:void(0)"
                    class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
                    v-b-tooltip.hover.bottom
                    :title="$t('TABLES.PROJECT_REPORT_TOOLTIPS.DESTROY')"
                    @click="deleteActivity(projectReport)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg src="media/svg/icons/Home/Trash.svg" />
                    </span>
                  </a>
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="projectReports.length == 0 && loadingComplete">
            <td class="px-2 text-center" colspan="7">
              <span class="text-dark-65 font-weight-bold d-block py-3">
                {{ $t('TABLES.PROJECT_REPORTS.NO_REPORTS_TEXT') }}
              </span>
            </td>
          </tr>
          <tr v-if="!loadingComplete && !error.active">
            <td class="px-2 text-center border-0" colspan="7">
              <b-spinner
                variant="primary"
                label="Spinning"
                class="table-loader my-3"
              >
              </b-spinner>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

import { DELETE_PROJECT_REPORT } from "@/core/services/store/project/project_reports.module";

export default {
  name: "ProjectReportsTable",

  props: [ 'pageLoader', 'error', 'projectReports' ],

  data() {
    return {
      search: "",
      hoverRow: null,
      status_select: {
        status: null,
        text: `${this.$t("SYSTEM.REPORT_STATUS.ANY")}`,
        variant: "primary"
      }
    };
  },

  components: {
    GenericDatetimeDisplay
  },

  methods: {
    reportInSearch(report) {
      let title = report.title.toLowerCase();
      let created_by = report.user.display_name.toLowerCase();
      let status = report.status_localised.toLowerCase();

      if (
        title.includes(this.search.toLowerCase()) ||
        created_by.includes(this.search.toLowerCase()) ||
        status.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    reportInStatusFilter: function(report) {
      if (this.status_select.status == null) {
        return true;
      } else {
        if (this.status_select.status == report.status) {
          return true;
        } else {
          return false;
        }
      }
    },
    statusFormat: function(status) {
      let format = null;

      if (status == 1) {
        format = "primary";
      } else if (status == 2) {
        format = "warning";
      } else if (status == 3) {
        format = "success";
      } else if (status == 4) {
        format = "danger";
      }

      return format;
    },
    downloadReport(report) {
      if (report.signed_url != null) {
        window.open(report.signed_url);
      }
    },
    deleteActivity(report) {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.REPORT_DELETE_CHALLENGE.TITLE")}`,
        text: report.title + " " + `${this.$t("SWEETALERTS.REPORT_DELETE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.REPORT_DELETE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            projectId: this.projectInfo.id,
            projectReportId: report.id
          };
          this.$store.dispatch(DELETE_PROJECT_REPORT, payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.REPORT_DELETE_SUCCESS.TITLE")}`,
              text: report.title + ' ' + `${this.$t("SWEETALERTS.REPORT_DELETE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.REPORT_DELETE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      })
    },
    setStatusSelect: function(status) {
      if (status == 1) {
        this.status_select = {
          status: status,
          text: `${this.$t("SYSTEM.REPORT_STATUS.1")}`,
          variant: 'primary'
        };
      } else if (status == 2) {
        this.status_select = {
          status: status,
          text: `${this.$t("SYSTEM.REPORT_STATUS.2")}`,
          variant: 'warning'
        };
      } else if (status == 3) {
        this.status_select = {
          status: status,
          text: `${this.$t("SYSTEM.REPORT_STATUS.3")}`,
          variant: 'success'
        };
      } else if (status == 4) {
        this.status_select = {
          status: status,
          text: `${this.$t("SYSTEM.REPORT_STATUS.4")}`,
          variant: 'danger'
        };
      } else {
        this.status_select = {
          status: null,
          text: `${this.$t("SYSTEM.REPORT_STATUS.ANY")}`,
          variant: 'primary'
        };
      }
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
