<template>
  <div class="col-xxl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('NAVIGATION.PROJECT_MENU.REPORTS') }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <ProjectReportsTable
          :pageLoader="pageLoader"
          :error="error"
          :projectReports="projectReports"
        >
        </ProjectReportsTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_PROJECT_REPORTS_LIST } from "@/core/services/store/project/project_reports.module";

import ProjectReportsTable from "@/view/content/projects/ProjectReportsTable";

export default {
  name: "ProjectReports",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      },
      refreshTimer: null
    }
  },

  components: { ProjectReportsTable },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      {
        title: this.projectInfo.name,
        route: "/project/view"
      },
      { title: `${this.$t("NAVIGATION.PROJECT_MENU.REPORTS")}` }
    ]);

    this.fetchProjectReports();

    this.refreshTimer = setInterval(() => {
      this.fetchProjectReports();
    }, 10000)
  },

  beforeDestroy() {
    clearInterval(this.refreshTimer)
  },

  methods: {
    fetchProjectReports: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          user: 'with'
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_REPORTS_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project reports list, please check back later or contact the helpdesk";
        });
    },
  },

  computed: {
    ...mapGetters(["clientInfo", "projectInfo", "projectReports"])
  }
};
</script>